
import { defineComponent, onMounted, ref, computed } from 'vue';
import {
  IonContent,
  IonPage,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonIcon,
  IonProgressBar,
} from '@ionic/vue';
import { addOutline, removeOutline } from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import VenueService from '@/services/venue.service';
import { VenueMap } from '@/types/interface';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HeaderMain,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    IonIcon,
    IonProgressBar,
  },
  setup() {
    const venueMaps = ref<VenueMap[]>([]);
    const mapSelected = ref<VenueMap>({
      id: '',
      name: '',
      url: '',
    });
    const mapImg = ref<HTMLImageElement>({} as HTMLImageElement);
    const scale = ref<number>(0);

    const defaultVenueMap = computed(() => {
      return venueMaps.value.length > 0 ? venueMaps.value[0].name : '';
    });

    const initFloorMaps = async () => {
      venueMaps.value = await VenueService.getMap(
        process.env.VUE_APP_EVENT_ID as string
      );
      mapSelected.value = venueMaps.value[0];
    };

    const onVenueMapChange = (e: CustomEvent) => {
      mapSelected.value = e.detail.value;
    };

    const zoomIn = () => {
      if (scale.value === 9) {
        return (mapImg.value.style.transform = `scale(2.${scale.value})`);
      }
      mapImg.value.style.transform = `scale(1.${++scale.value})`;
    };
    const zoomOut = () => {
      if (scale.value !== 0) {
        mapImg.value.style.transform = `scale(1.${(scale.value -= 1)})`;
      }
    };
    onMounted(() => {
      initFloorMaps();
    });
    return {
      venueMaps,
      defaultVenueMap,
      mapSelected,
      onVenueMapChange,
      removeOutline,
      addOutline,
      zoomIn,
      zoomOut,
      mapImg,
    };
  },
});
